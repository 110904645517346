.dropdown-menu{
    border-radius: 16px;
    border: 0.7px solid #E3E3E3;
    background:  #FFF;
    padding: 4px;
    overflow: hidden;

    /* Shadow sm */
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    .dropdown-item{
        border-radius: 12px;
        background: transparent;
        color: #6A6A6A;

        /* Body/16 Reguler */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.2px;
    }
}
/* 原来的样式 */
.full-screen {
    height: 100vh; /* 设置为全屏 */
}

/* 顶部栏样式 */
.navbar {
    background-color:#FFFFFF; /* 深色背景 */
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr auto ;
    align-items: center;
    padding: 20px 80px;
    gap: 16px;
}

@media screen and (max-width: 768px){
    .navbar {
        padding:16px;
    }
}

/* Logo样式 */
.navbar-brand img {
    height: 34px; /* 高度 */
    width: 34px; /* 宽度自动 */
}

/* 网站名字样式 */
.navbar-brand {
    width: fit-content;
    color: #252525;
    font-family: Epilogue;
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: 160%; /* 32px */
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto 1fr;
    align-items: center;
    gap:8px ;
}

.navbar-brand span{
    font-weight: 500;
    line-height: 160%; /* 32px */
    margin-top: -6px;
}

.lang {
    min-width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #F5F5F5;
}

.lang .dropdown-toggle{
    padding: 0 !important;
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    &::after{
        display: none !important;
    }
}

.lang .dropdown-menu{
    right: 0;
    left: auto;
}

.nav{
    gap: 16px;
}


.btn{
    width: 100%;
    border: none;
    border-radius: 12px;
    background: #040404;
    padding: 12px 20px;
    &:hover{
        background: #040404;
    }
    &:active{
        background: #040404;
    }
}




.panel-button-selected {
    border-radius: 12px;
    background-color:#fff !important;
}

.panel-container {
    overflow: auto;
    background-color: #fff;
    padding: 40px 80px;
    display: grid;
    height: fit-content;
    gap:24px ;
    max-width: 1280px;
    @media screen and (max-width: 768px) {
        padding: 40px 16px;
    }
}

.panel-container::-webkit-scrollbar {
    width: 12px;
}

.panel-container::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 6px;
}

.panel-container::-webkit-scrollbar-track {
    background-color: #333;
}


.panel-header {
    padding: 20px;
    display: flex; /* 使用 Flexbox */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
}

.panel-button {
    width: 100%;
    background-color:transparent; /* 更深的黄色 */
    border-color: transparent;
    white-space: nowrap;
    color: #6A6A6A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    border-radius: 12px !important;
}

.panel-button:hover,
.panel-button:focus,
.panel-button:active {
    background-color:#FFFFFF;
    border-color: #FFFFFF;
}

.panel-content {
    background-color: #333333;
}

/* 新的样式 */
.content-list {
    border-radius: 12px;
    border: 0.7px solid #E3E3E3;
    background: #FFF;
    padding: 16px 20px;
    min-height: 50vh;
}


/* PC屏幕大于等于md宽度（典型的PC屏幕宽度）时，设置内容排列 */
@media (min-width: 768px) {
    .content-list {
        display: grid; /* 使用网格布局 */
        grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
        gap: 22px; /* 列之间的间距 */
    }
}

/* 大字体样式 */
.content-text {
    color:#252525;
    /* Body/16 Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.2px;
}

.content-item-center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 0.7px solid #E3E3E3;
    background: #FFF;
    min-height: 40px;
}

.highlight-yellow {
    color: #F0B90B; /* 高亮黄色 */
}

/* 通用样式 */
.floating-button {
    position: fixed;
    bottom: 30px;
    right: 50px;
    z-index: 9999;
    font-size: 20px; /* 默认字体大小 */
    padding: 10px; /* 默认内边距 */
}

.bi {
    width: 1em;
    height: 1em;
    fill: currentColor;
}

/* 移动设备和小屏幕 */
@media (max-width: 768px) {
    .content-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .content-list .content-item {
        flex: 1 0 calc(33.33% - 16px); /* 33.33% 减去间隔宽度 */
        margin-right: 10px; /* 设置项目间的间隔 */
        margin-bottom: 10px; /* 设置项目下方的间隔 */
    }

    .floating-button {
        font-size: 18px; /* 字体稍大 */
        padding: 10px; /* 增大内边距 */
        right: 30px;
    }


    /* 新增的翻译按钮样式 */
    .translate-dropdown {
        position: absolute;
        top: 6px;
        right: 10px;
        z-index: 1000; /* 确保按钮总在最顶层 */
    }

    .navbar-brand img {
        height: 40px; /* 高度 */
        width: 40px; /* 宽度自动 */
        /*margin-top: 0px; !* 距离顶部的距离 *!*/
        /*margin-left: 20px; !* 距离左边的距离 *!*/
        /*margin-right: 10px; !* Logo和名字之间的间距 *!*/
    }

    /* 网站名字样式 */
    .navbar-brand {
        font-size: 28px; /* 字体大小 */
        font-weight: bold; /* 加粗 */
        color: #ffffff !important; /* 字体颜色 */
    }
}

.header-title-box {
    display: grid;
    height: fit-content;
    gap: 8px;
}
.header-title-box .title{
    color: #252525;
    /* Heading medium/Semibold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 125% */
    letter-spacing: -1px;
}
.header-title-box .text{
    color: #6A6A6A;

    /* Body/18 Reguler */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.2px;
}

/* 筛选 */
.bnt-box{
    border-radius: 16px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    padding: 4px;
    @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}

/* 工具 */
.tool{
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: auto 1fr auto;
    height: fit-content;
    @media screen and (max-width: 768px) {
        grid-auto-flow: row;
        .flex{
            display: none;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        grid-auto-flow: row;
    }
}

.tool .r{
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap:20px ;
}

.tool .r .sort button{
    background:  #F5F5F5;
    color: #252525;
    /* Body/14 Reguler */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
    display: flex;
    justify-content: center;
    grid-auto-flow: column;
    height: fit-content;
    gap: 10px;
    &::after{
        display: none;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        width: 100%;
    }
}

.tool .r .sort button span{
    width: 140px;
    @media screen and (max-width: 768px) {
        width: auto;
    }
}

.tool .r .sort button .ChevronDownSvg{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background:  #E3E3E3;
}
a{
    text-decoration: none !important;
}

.container{
    display: grid;
    gap: 12px;
    height: fit-content;
}