.panel-containers{
    width: 100%;
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        padding:16px ;
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        padding: 16px 80px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        padding: 16px 80px;
    }
}

.panel-containers span{
    color: #252525;

    /* Body/16 Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.2px;
}

.text-box{
    display: grid;
    gap: 20px;
    height: fit-content;
}

.text-box textarea{
    height:260px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid  #F5F5F5;
    background:  #F5F5F5;
    padding: 16px;
    outline: none;
}

.query-box{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2,1fr);
    gap: 12px;
}

.list-box{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2,1fr);
    gap: 12px;
}

.list-box .content-item-center{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr auto auto;
    padding: 23px 16px;

}
.hr{
    width: 0.7px;
    height: 18px;
    background: #E3E3E3;
    margin-right: 24px;
}

.highlight-yellow{
    color: #F0B90B !important;
}

.content-text{
    color:  #252525 !important;
    font-family: Inter;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.2px;
}
.box{
    display: grid;
    gap: 12px;
    height: fit-content;
}